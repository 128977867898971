
            @import "@/assets/sass/vars.portal.scss";
          





































.v-flag-meta {
    margin-top: 5px; padding-top: 5px;
    border-top: 1px dotted $border-color;
    
}
