
            @import "@/assets/sass/vars.portal.scss";
          

















.v-m-allocations {
    .badge {
        font-size: 12px;
        margin-right: 4px;
    }
}
