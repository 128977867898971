
            @import "@/assets/sass/vars.portal.scss";
          












































































































.v-favorite-filter {
    i.fal {
        color: rgb(96, 98, 102);
    }

    .fav-label {
        margin-left: 3px;
        color: rgb(96, 98, 102);
        font-weight: 400;
    }
}
.el-dropdown-link i {
    margin-right: 5px;
}
