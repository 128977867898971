
            @import "@/assets/sass/vars.portal.scss";
          














































































































































































.v-portfolio-stage-table {
    &__empty_stage {
        border: 1px solid $gray-200;
        border-radius: 6px;
        padding: 10px;
        font-size: 11px;
        color: $gray-600;
    }

    &__title {
    }

    &__type {
        width: 100px;
    }
    &__status {
        width: 120px;
    }
    &__pm {
        width: 190px;
    }
    &__milestone {
        width: 120px;
    }
    &__municipality {
        width: 180px;
        overflow: hidden;
    }
    &__flags {
        width: 200px;
    }
}
