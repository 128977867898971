
            @import "@/assets/sass/vars.portal.scss";
          






































































































































































































































.v-cost-detailed-budget-report {
    .v_year {
        display: flex;
        margin-bottom: 30px;
    }

    .v_month {
        font-size: 10px;
        text-align: right;
        border-top: 1px solid $gray-300;
        border-right: 1px solid $gray-300;
        border-bottom: 1px solid $gray-300;
        padding: 8px 4px 4px 8px;
        flex: 0 0 calc(100% / 12); /* Always 12 equal parts */
        min-width: calc(100% / 12);

        &:first-of-type {
            border-left: 1px solid $gray-300;
        }

        &__clickable:hover {
            background: $primary;
            cursor: pointer;
            * {
                color: white !important;
            }
        }

        border-radius: 6px;
    }

    .v_mname {
        text-align: left;
        color: $gray-600;
    }
}
