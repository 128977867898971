/** 
Variables for PORTAL 
*/

/* FOUNDATION */
//
//$default: #434f69;
$default: #0B2440;
$primary:       #789e6b; // or d2d755 ced665 // 9FBA7F
$secondary:     #FFFFFF ;
$success:       #80C668;
$info:          #20A4F3 ;
$warning:       #F29A33 ;
$danger:        #DE2123 ;
$white: 		#ffffff;
$active:        #789e6b; 

// Import from Effihub
$effi-input-bg: #F3F4F5;
$effi-border:   #e7eaec;
$brand-effi: $default;
$brand-primary: $primary;
$brand-secondary: $default;
$brand-success: $success;
$brand-info: $info;
$brand-warning: $warning;
$brand-danger: $danger;

$gray-base:              #000;
$gray-darker:            lighten($gray-base, 13.5%); // #222
$gray-dark:              lighten($gray-base, 20%);   // #333
$gray:                   lighten($gray-base, 33.5%); // #555
$gray-light:             lighten($gray-base, 46.7%); // #777
$gray-light2:             lighten($gray-base, 66.7%); // #777
$gray-light3:             lighten($gray-base, 86.7%); // #777
$gray-lighter:           lighten($gray-base, 93.5%); // #eee

$border-color: #b8c1bf; 
/* Big things in the top */
$body-bg:   #eff0ee;


$white:    #fff ;
$gray:     #F3F7F5 ; 
$gray-label: #bcc2bf; 
$gray-100: #eff3f2 ;
$gray-200: #e8eeed ;
$gray-300: #d9e2e0 ;
$gray-400: #d0d9d7 ;
$gray-500: #b8c1bf ;
$gray-600: #7c8584 ;   // Line footer color
$gray-700: #69716f ;   // Line p color
$gray-800: #4d5352 ;   // Line heading color
$gray-900: #313636 ;
$black:    #000 ;

$text-gray: #676A6C;

// Fonts
$font-family-sans-serif: 'Open Sans', sans-serif;

$body-color:    #0d3233;
$headings-color: #0d3233;

// Tables 
$table-head-font-size:        12px  !important;
$table-head-color:            #095051 !important;
$table-head-text-transform: uppercase !important;
