
            @import "@/assets/sass/vars.portal.scss";
          
























































































































































































































































































































































































































































































































































































































































































































































































































































































.hover-danger {
    &:hover {
        color: $danger !important;
    }
}
.v-p-invoice {
    &__totals {
        height: 100%;
        align-self: center;
        align-items: center;
        display: flex;
        justify-content: center;
        background-color: $gray-100;
        border: 6px solid $gray-100;
        border-radius: 6px;
        flex-direction: column;

        &--header {
            display: block;
            margin-bottom: 6px;
        }

        &--amount {
            font-size: 22px;
            font-weight: 600;
            display: block;
        }
    }
}
