
            @import "@/assets/sass/vars.portal.scss";
          





































































































































































































































































































































































































































































































































































































































.voucher-data {
    margin-bottom: 30px;
    padding-bottom: 15px;
    border-bottom: 1px solid $gray-200;
}
.c-alloc {
    border: 1px solid $gray-500;
    border-radius: 6px;
    margin-bottom: 20px;
    padding: 12px 28px 28px 28px;

    &__milestone {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        padding-bottom: 15px;
        font-size: 18px;
        font-weight: 600;
        color: $gray-800;
        border-bottom: 1px solid $gray-600;

        &.has-error {
            border: 2px dotted $danger;
            padding: 10px;
            border-radius: 6px;
        }
    }

    &__mname {
        flex-grow: 1;
    }

    &__amount {
        flex: 0 0 300px;
        text-align: right;
        padding-left: 10px;
        padding-right: 8px;

        input {
            border: 1px solid $gray-500;
            border-radius: 6px;
            height: 25px;
            width: 200px;
        }
    }

    .c-cats {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px;

        &.c-cats__nonzero {
            font-weight: 600;
            input {
                font-weight: 600;
            }
        }

        &.c-cats__zero {
            color: $gray-600;
            input {
                color: $gray-600;
            }
        }

        &__label {
            font-size: 13px;
            flex-grow: 1;
            border-bottom: 1px dotted $gray-600;
            margin-right: 10px;

            &-cost {
            }

            &-noncost {
                color: $warning;
                font-weight: bold;
            }
        }

        &__input {
            flex: 0 0 140px;
            input {
                border: 1px solid $gray-500;
                font-size: 12px;
                padding-left: 5px;
                border-radius: 6px;
                width: 100px;
                height: 24px;
            }

            button {
                margin-left: 5px;
            }
        }
    }
}
