
            @import "@/assets/sass/vars.portal.scss";
          





























































































































































































































































































































































.v-infobar__infobox {
    margin-top: 25px;
    margin-bottom: 34px;
}

.info-liner {
    font-size: 15px;
}

.project-description {
    font-size: 15px;
    margin-bottom: 20px;
}

.status-label {
    font-weight: 600;
    color: $headings-color;
}
