
            @import "@/assets/sass/vars.portal.scss";
          









































































































































































































































































.v-project-report-group {
    .table__flag {
        td {
            text-wrap: wrap;
        }

        &_title {
        }
        &_type {
            width: 90px;
            text-align: center;
        }
        &_milestone {
            width: 60px;
        }
        &_subtype {
            width: 170px;
        }
        &_timeline_change {
            width: 170px;
        }
        &_cod {
            width: 170px;
        }
        &_category {
            width: 160px;
        }
        &_created {
            width: 120px;
        }
        &_impact {
            width: 340px;
        }

        &_impact2 {
            width: 1010px;
        }

        &_how {
            width: 340px;
        }
    }

    .flag {
        &-green {
            color: $success;
        }

        &-orange {
            color: $warning;
        }

        &-red {
            color: $danger;
        }
    }
}
