
            @import "@/assets/sass/vars.portal.scss";
          











































































































































































.v-planning-report {
    .form-group {
        padding-bottom: 0px !important;
        margin-bottom: 0px !important;
    }
}
