
            @import "@/assets/sass/vars.portal.scss";
          








































































































































































































































































































































































.change-customer-user-row {
    padding-top: 15px;
    border-top: 1px solid $border-color !important;
}

div.change-customer-user-row:last-child {
    border-bottom: 1px solid $border-color !important;
}
