
            @import "@/assets/sass/vars.portal.scss";
          











































.outer-dash-card {

    box-shadow: none;
    border: 1px solid white; 
    border-radius: 6px;

    &__slots {
        display: flex;
        align-items: baseline;        
    }

    &__left {
        //border: 1px dotted red;
        font-size: 30px; 
        font-weight: 600;

        &--small {
            margin-top: 8px;
            margin-bottom: 4px;
            font-size:23px;
        }
    }    

    &__subtitle {
        //border: 1px solid red;
        color: $gray-600;
        font-size: 12px;
        
    }

    .card-title {
        font-weight: bold;
    }    

    & .card-body {
        padding: 18px;
    }

    &__icon {        
        font-size: 20px;
    }
}
