
            @import "@/assets/sass/vars.portal.scss";
          

































































































































































































































































































































































































































































































































































































































.planning-type {
    border: 1px solid $gray-600;
    border-radius: 6px;
}
