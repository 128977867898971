
            @import "@/assets/sass/vars.portal.scss";
          














































.v-timeline-diff {
    .vtd_box {
        // border: 1px solid red;
        padding-top: 8px;

        &__title {
            font-size: 11px;
            color: $gray-700;
        }

        &__data {
            font-size: 12px;
            color: $default;
            .fal {
                color: $primary;
            }
        }
    }
}
