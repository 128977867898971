
            @import "@/assets/sass/vars.portal.scss";
          



































































#pdfViewer {
    width: 100%;
    height: 100%;
    border: none; /* No border around the iframe */
}
