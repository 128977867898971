
            @import "@/assets/sass/vars.portal.scss";
          




































































































































































.cost-struct {
    padding-left: 15px;
    padding-right: 15px;

    display: flex;
    justify-content: space-between;

    &__container {
        margin-bottom: 40px;
    }

    &__ldata {
     
        flex-grow: 3;
        .milestone-name {
        }

        .milestone-dates {
        }

        .cost-struct__note {
        }
    }

    &__rdata {
        flex-grow: 2;
        
    }

    &__money {
        text-align: right;
        flex: 5 0;
     
    }

    &.milestone-m-ident {
        border-bottom: 2px solid $gray-500;

        font-size: 24px;
        font-weight: 600;
    }

    &.milestone-details {
        margin-top: 15px;
    }

    .cat-costs {
        display: flex;
        justify-content: space-between;

        &-noncost {
            color: $warning;
            font-weight: bold;
        }

        &__name {
            padding-left: 10px;
            padding-top: 5px;
            padding-bottom: 5px;
            flex-grow: 1;
            border-bottom: 1px solid $gray-500;
        }
        &__money {
            padding-top: 5px;
            padding-bottom: 5px;
            border-bottom: 1px solid $gray-500;
            text-align: right;
            flex: 0 0 200px;            
        }
    }

    .date-specs {
        color: $gray-700;
        margin-left: 20px;
        font-size: 14px;
        font-weight: normal;
    }
}
