
            @import "@/assets/sass/vars.portal.scss";
          









































































.navi-block {
    font-size: 15px;
    font-weight: 400;
    position: relative;
    white-space: nowrap;
    color: $gray-600;
    padding-top:2px;    

    &.active {
        color: $primary;
    }

    &__shadow {
        position: absolute;
        width: 100%;
        height: 5px;
        background: $primary;
        top: 44px;
        border: 1px solid $primary;
    }
}
