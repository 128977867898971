
            @import "@/assets/sass/vars.portal.scss";
          


























































.date-today {
    color: $warning;
}

.date-overdue {
    color: $danger;
}

.date-soon {
    color: $body-color;
}

.date-future {
    color: $body-color;
}
.date-task-inactive {
    color: $body-color !important;
}

@media (max-width: 992px) {
    .date-future {
        color: #aaabad;
    }
}
