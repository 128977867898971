
            @import "@/assets/sass/vars.portal.scss";
          






































































































































































































































.v-portfolio-stage-container {
    margin-bottom: 50px;
}
