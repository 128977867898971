
            @import "@/assets/sass/vars.portal.scss";
          

























































































































































































.v-tabler {
    .form-group {
        margin-bottom: 0px !important;
    }
}
.table-manager {
    table-layout: fixed;

    .td-thin {
        padding-left: 5px !important;
        padding-right: 5px !important;
        text-align: center;
        width: 30px !important;
    }
}
