
            @import "@/assets/sass/vars.portal.scss";
          










































.inner-dash-card {

    box-shadow: none;
    border: 1px solid $gray-400; 
    border-radius: 6px;

    &__footer {
        margin-top: 10px;
        &--content {
            color: $gray-600;
            font-size: 14px;
        }
    }

    &__slots {
        display: flex;
        align-items: baseline;
    }

    &__left {
        //border: 1px dotted red;
        font-size: 20px; 
        font-weight: 600;
    }

    &__right {
        //border: 1px dotted green;
        text-align: right;
        font-size: 13px;
    }

    .card-title {
        font-weight: bold;
    }    

    & .card-body {
        padding: 18px;
    }

    &__icon {        
        font-size: 20px;
    }
}
