
            @import "@/assets/sass/vars.portal.scss";
          






























































































































































































































.milestone-is-added {
    td {
        color: $success !important;
        font-weight: bold;
    }
}

.milestone-is-not-added {
    td {
        color: #777 !important;
    }
}
