
            @import "@/assets/sass/vars.portal.scss";
          


































































































































































































































































































































































































.v-filters {
    @media (max-width: 767.98px) {
        &__container {
            &__right {
                & > span {
                    margin-bottom: 15px;
                }
            }
        }
    }
}

.table-responsive {
    overflow-x: auto !important;
}

.column-help-text {
    color: $primary;
}

.help-content li {
    margin-bottom: 20px;
}
